import * as React from 'react';
import { AlbumData } from '../data/albumData';
import AlbumGrid from '../components/albumGrid';
import Layout from "../components/layout";
import * as styling from '../styles/styleconstants.js'





// markup
const Albums = ({data}) => {
  return (
    <Layout>
    <main style={styling.pageStyles}>
      <title>Albums</title>
      <h1 style={styling.headingStyles}>
        Albums
      </h1>
        <AlbumGrid albumData={AlbumData}/>
      <p style={styling.bottomSpacer}> </p>
      <iframe src="https://open.spotify.com/embed/playlist/1vxWlOJy7wkeylW9Fq6lKJ?utm_source=generator&theme=0" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      <p style={styling.bottomSpacer}> </p>
    </main>
    </Layout>
  )
}



export default Albums

