export const AlbumData = {
    albumOne: {
        filePath: "albumOneStatic",
        spotify: "https://open.spotify.com/album/4er9jU26Y9f52PusWKHKRg",
        appleMusic: "https://music.apple.com/us/album/kaplan-8-preludes-ogonek-orpheus-suite-bach-goldberg/1523538662",
        amazon: "https://www.amazon.com/Kaplan-Preludes-Orpheus-Goldberg-Variations/dp/B08CQBQ62X"
    },
    albumTwo: {
        filePath: "albumTwoStatic",
        spotify: "https://open.spotify.com/album/3xJIiOu6nz5xaIUEzRBFCT",
        appleMusic: "https://music.apple.com/us/album/j-s-bach-the-well-tempered-clavier-book-ii/1473156568",
        amazon: "https://www.amazon.com/J-S-Bach-Well-Tempered-Clavier-Book/dp/B07V52H894"
    },
    albumThree: {
        filePath: "albumThreeStatic",
        spotify: "https://open.spotify.com/track/2TyUMkxszHXOBmjOu8hXOl?si=e5f7607b7f2a4303",
        appleMusic: "https://music.apple.com/us/album/snippet-variations-op-18/1596814172?i=1596814366",
        amazon: "https://www.amazon.com/dp/B09MR8B7C5/ref=dm_ws_tlw_trk2" 
    },
    albumFour: {
        filePath: "albumFourStatic",
        spotify: "https://open.spotify.com/track/3DazAR91deV9wMCsQV0bd4?si=78ef89704a7a4242",
        appleMusic: "https://music.apple.com/us/album/water-cantos-notes-from-quiet-places-i-processional/1556511328?i=1556511331",
        amazon: "https://www.amazon.com/Hartke-Ogonek-Jones-Chamber-Works/dp/B08XYCXMQ3/" 
    }
}