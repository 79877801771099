import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import * as styles from "../styles/styleconstants"

const AlbumWrap = styled.div`
  width:310px;
  height: 310px;
  marginBottom: 64px;
  border: 2px solid black;
  border-radius: 5px;
  `

const albumGrid = {
    display: "grid",
    gridGap: "2rem",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",

}

const AlbumGrid = ({ albumData }) => {
    const data = useStaticQuery(graphql`
    query {

      albumOne: file(name: { eq: "albumOneStatic" }, extension: {eq: "jpg"}) {
        childImageSharp{
          fluid(maxWidth:5000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      albumTwo: file(name: { eq: "albumTwoStatic" }, extension: {eq: "jpg"}) {
        childImageSharp{
          fluid(maxWidth:5000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      albumThree: file(name: { eq: "albumThreeStatic" }, extension: {eq: "jpg"}) {
        childImageSharp{
          fluid(maxWidth:5000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      albumFour:  file(name: { eq: "albumFourStatic" }, extension: {eq: "jpg"}) {
        childImageSharp{
          fluid(maxWidth:5000,quality:100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

    }
  `)
    return (
            <div style={albumGrid}>
                <AlbumWrap>
                  <Img fluid={data.albumOne.childImageSharp.fluid} alt="logo" className ="rounded"/>  
                  <a href={albumData.albumOne.spotify} style={styles.altLinkStyle}>spotify</a> | 
                  <a href={albumData.albumOne.appleMusic} style = {styles.altLinkStyle}> apple</a> | 
                  <a href={albumData.albumOne.amazon} style = {styles.altLinkStyle}> amazon</a> 
                </AlbumWrap>

              <AlbumWrap>
                <Img fluid={data.albumTwo.childImageSharp.fluid} alt="logo" className ="rounded"/>  
                <a href={albumData.albumTwo.spotify} style={styles.altLinkStyle}>spotify</a> | 
                <a href={albumData.albumTwo.appleMusic} style = {styles.altLinkStyle}> apple</a> | 
                <a href={albumData.albumTwo.amazon} style = {styles.altLinkStyle}> amazon</a> 
              </AlbumWrap>  

              <AlbumWrap>
                <Img fluid={data.albumThree.childImageSharp.fluid} alt="logo" className ="rounded"/>  
                <a href={albumData.albumThree.spotify} style={styles.altLinkStyle}>spotify</a> | 
                <a href={albumData.albumThree.appleMusic} style = {styles.altLinkStyle}> apple</a> | 
                <a href={albumData.albumThree.amazon} style = {styles.altLinkStyle}> amazon</a> |
                <span> (tracks 2-5) </span>
              </AlbumWrap> 

              <AlbumWrap>
                <Img fluid={data.albumFour.childImageSharp.fluid} alt="logo" className ="rounded"/>  
                <a href={albumData.albumFour.spotify} style={styles.altLinkStyle}>spotify</a> | 
                <a href={albumData.albumFour.appleMusic} style = {styles.altLinkStyle}> apple</a> | 
                <a href={albumData.albumFour.amazon} style = {styles.altLinkStyle}> amazon</a> |
                <span> (track 2) </span>
              </AlbumWrap> 
             </div>
    )
  }
  
  export default AlbumGrid